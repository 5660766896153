import React from 'react';
import { Link } from 'gatsby';

function BlogTiles({ posts }) {
  return posts.map((post, index) => (
    <div
      key={index}
      className={'column is-4 ' + (index % 2 === 1 ? 'is-offset-1' : '')}
    >
      <h2>
        <Link className="blog-title" to={post.node.fields.slug}>
          {post.node.frontmatter.title}
        </Link>
      </h2>
      <div>
        <p className="blog-subtitle">{post.node.frontmatter.description}</p>
      </div>
    </div>
  ));
}

export default BlogTiles;
