import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BlogTiles from '../components/blog/BlogTiles';
import Pagination from '../components/blog/Pagination';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';

const BlogIndexTemplate = ({ posts, pageContext }) => (
  <>
    <div className="columns is-centered">
      <div className="column is-9">
        <PageHeader title="Blog" />
        <hr />
      </div>
    </div>
    <div className="columns is-centered is-multiline">
      <BlogTiles posts={posts} />
    </div>
    <div className="columns is-centered">
      <div className="column is-9">
        <Pagination {...pageContext} />
      </div>
    </div>
  </>
);

function BlogIndexPage({ data, pageContext }) {
  const { allMarkdownRemark: post } = data;

  return (
    <Layout showHeader={false}>
      <Seo title="Blog" description="" />
      <BlogIndexTemplate posts={post.edges} pageContext={pageContext} />
    </Layout>
  );
}

export default BlogIndexPage;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { content_type: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`;
