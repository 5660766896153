import React from 'react';
import { Link } from 'gatsby';
import PaginationLink from './PaginationLink';

function Pagination({ currentPage, numPages }) {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <nav
      className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <PaginationLink
        className="pagination-previous"
        text="← Forige side"
        bool={isFirst}
        rel="prev"
        page={prevPage}
      />
      <PaginationLink
        className="pagination-next"
        text="Næste side →"
        bool={isLast}
        rel="next"
        page={nextPage}
      />
      <ul className="pagination-list">
        {Array.from({ length: numPages }, (_, i) => (
          <li key={`pagination-number${i + 1}`}>
            <Link
              className={
                'pagination-link ' +
                (i + 1 === currentPage ? 'is-current disabled-link' : '')
              }
              to={`/blog/${i === 0 ? '' : i + 1}`}
            >
              {i + 1}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
