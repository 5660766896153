import React from 'react';
import { Link } from 'gatsby';

function PaginationLink({ bool, page, text, rel, className }) {
  if (!bool) {
    return (
      <Link className={className} to={`/blog/${page}`} rel={rel}>
        {text}
      </Link>
    );
  }

  return (
    <div className={className} rel={rel} disabled>
      {text}
    </div>
  );
}

export default PaginationLink;
